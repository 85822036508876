// import './App.css';
// import Home from "./pages/Home";
import "react-toastify/dist/ReactToastify.css";
import fox from "./images/fox.png";
import React, { useEffect, useState, startTransition, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import loadingg from "./images/loadinggif.gif";


const Home = lazy(() => import("./pages/Home"));
const Collegedetails = lazy(() => import("./pages/Collegedetails"));
const About = lazy(() => import("./pages/About"));
const Contactus = lazy(() => import("./pages/Contactus"));
const Searchresult = lazy(() => import("./pages/Searchresult"));
const Navbar = lazy(() => import("./components/Navbar"));
const Facnav = lazy(() => import("./components/Facnav"));
const Studentlogin = lazy(() => import("./pages/Studentlogin"));
const Joberole = lazy(() => import("./pages/Jobrole"));
const Jobdesc = lazy(() => import("./pages/Jobdesc"));
const Facultyreg = lazy(() => import("./pages/Facultyreg"));
const Searchrole = lazy(() => import("./pages/Searchrole"));
const Facforgotpass = lazy(() => import("./pages/Facforgotpass"));
const Facotp = lazy(() => import("./pages/Facotp"));
const Facupdpass = lazy(() => import("./pages/Facupdpass"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Studentsignup = lazy(() => import("./pages/Studentsignup"));
const Termsandconditions = lazy(() => import("./pages/Termsandconditions"));
const Forgetpass = lazy(() => import("./pages/Forgetpass"));
const Sendotp = lazy(() => import("./pages/Sendotp"));
const Createnewpass = lazy(() => import("./pages/Createnewpass"));

function App() {
  const location = useLocation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      startTransition(() => setIsOnline(true));
      toast.success("You are online");
    };

    const handleOffline = () => {
      startTransition(() => setIsOnline(false));
      // toast.error("No Internet Connection");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const showFacultyNav = ["/Searchrole", "/Jobdesc"].includes(location.pathname);

  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="w-full h-screen">
          <img
            className=" mx-auto my-[28%] "
            src={loadingg}
            alt="loading..."
            // style={{ marginTop: "20%", marginLeft: "40%" }}
            />
            </div>
        }
      >
        {showFacultyNav ? <Facnav /> : <Navbar />}
        
        {isOnline ? (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Searchresult" element={<Searchresult />} />
            <Route path="/Collegedetails/:id" element={<Collegedetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/studentlogin" element={<Studentlogin />} />
            <Route path="/Studentsignup" element={<Studentsignup />} />
            <Route path="/Facultyreg" element={<Facultyreg />} />
            <Route path="/Jobrole" element={<Joberole />} />
            <Route path="/Searchrole" element={<Searchrole />} />
            <Route path="/Jobdesc" element={<Jobdesc />} />
            <Route path="/Facforgotpass" element={<Facforgotpass />} />
            <Route path="/Facotp" element={<Facotp />} />
            <Route path="/Facupdpass" element={<Facupdpass />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/termsandconditions" element={<Termsandconditions />} />
            <Route path="/Forgetpass" element={<Forgetpass />} />
            <Route path="/Sendotp" element={<Sendotp />} />
            <Route path="/Createnewpass" element={<Createnewpass />} />
          </Routes>
        ) : (
          <div
            className="offline"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBlock: "22vh",
            }}
          >
            <img
              className="confused"
              src={fox}
              alt="confused"
              style={{ height: "400px" }}
            />
            <h1 className="text-3xl font-semibold">You Are Offline</h1>
          </div>
        )}
      </Suspense>
      <ToastContainer />
    </div>
  );
}

export default App;